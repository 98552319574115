import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Basket } from './basket';

function App() {
  return (
    <>
      <header>
        <div className="styl">
          <Row noGutters >
          <Col xs='12' md='4'><a className="navbar-brand" href="../"><Image src="logo.png" alt="logo" /></a></Col>
	  <Col xs='12' md='4' className="pt-3 eos">Konec prodeje 31.12.2024</Col>
            <Col xs='12' md='4' className="pt-3">
              <Row noGutters >
                <Col className="text-right mt-2 mr-2">
                  <Button href="/voucher/" size='sm' variant="success" >Uplatnit voucher na e-známku</Button>
                </Col>
		{/*
                <Col xs='auto' className="mt-4 mr-4 mb-0 pb-0">		  
                  <Form.Group controlId="lang">
                    <Form.Control size="sm" as="select">
                      <option>CZ</option>
                      <option>EN</option>
                      <option>PL</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
		*/}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="styl2">
          <p>&nbsp;</p>
        </div>
      </header>
      <main>
        <Basket />
      </main >
    </>
  );
}

export default App;
